.divbodyUWL {
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
   background: url(../../logos/UWL-Background.jpg);
   width: 100%;
   height: 100%;
   overflow-y: auto;
}

.bk-color {
   background-color: rgb(0, 106, 108, 0.6);
   min-height: 100%;
   letter-spacing: 0.02em;
   font-weight: 400;
   padding-left: max(144px, 10%);
   padding-right: max(144px, 10%);
   -webkit-font-smoothing: antialiased;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
   font-size: small;
}

.MuiInputBase-input.MuiInput-input {
   font-size: small;
}

.MuiFormControl-root.MuiTextField-root {
   width: -webkit-fill-available;
   width: -moz-available;
}

.MuiContainer-root.MuiContainer-fixed.MuiContainer-maxWidthLg {
   filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.3));
}

.MuiGrid-root.MuiGrid-item {
   padding: 1%;
}
