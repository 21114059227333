.DivisionNew-title{
   background-color: #002D72; 
   color: white; 
   text-align: center
 }
 
  .DivisionNew-cards{
   height: 100%; 
   width: 100%; 
   max-height: 400px; 
   overflow: auto;
  }
 
 .DivisionNew-card-header1{
   background-color: #002D72;
   color: white;
 }
 
 .DivisionNew-card-header2{
   background-color: #010440;
   color: white;
 }