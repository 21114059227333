.ApplicationView{
  margin: 0;
  padding: 0;
 }

 .ApplicationView-root{
  margin-top: 78px;
  height: calc(100vh - 78px);
  overflow: hidden;
  background-color: #F2F2F2;
 }

 .ApplicationView-header{
  border-bottom: 1px solid #2F3136;
  height: 60px;
  width: 100vw;
  z-index: 2;
  background-color: #F2F2F2;
  color: #778BA3;
  position: sticky;
  top: 0;
 }

 .ApplicationView-body{
   position: relative;
   width: 100%;
   height: calc(100vh - 138px);
   background: inherit;
   overflow: auto;
   z-index: 0;
   background-color: #FAFBFC;
 }

 .ApplicationView-body-reviewed{
  position: relative;
  width: 100%;
  height: calc(100vh - 198px);
  background: inherit;
  overflow: auto;
  z-index: 0;
  background-color: #FAFBFC;
}

 .ApplicationView-footer{
  border-bottom: 1px solid #2F3136;
  height: 60px;
  width: 100vw;
  z-index: 2;
  background-color: #F2F2F2;
  color: #778BA3;
  position: sticky;
  bottom: 0;
  justify-content: center;
  display: flex;
 }
 
 .ApplicationView-layout{
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;
  z-index: 1;
  color: white;
}

.ApplicationView-layout-drawer-open{
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;
  z-index: 1;
  color: white;
}

.ApplicationView-invite {
  float: right;
  margin-right: 1rem;
  background-color: #e6e3e3;
  color: #778BA3;
  border-radius: 4px;
  min-width: 100px;
  text-align: center;
}

.ApplicationView-invite:hover {
  background-color: #dddada;
}

.ApplicationView-invite-label {
  flex-direction: column;
}

.ApplicationView-card-header1{
  background-color: #002D72;
  color: white;
}

.ApplicationView-card-header2{
  background-color: #010440;
  color: white;
}

.ApplicationView-card-header3{
  background-color: #45484D;
  color: white;
}

.ApplicationView-card-header4{
  background-color: #778BA3;
  color: white;
}