.UserNew-title{
  background-color: #002D72; 
  color: white; 
  text-align: center
}

 .UserNew-cards{
  height: 100%; 
  width: 100%; 
  max-height: 400px; 
  overflow: auto;
 }

.UserNew-card-header1{
  background-color: #002D72;
  color: white;
}

.UserNew-card-header2{
  background-color: #010440;
  color: white;
}
