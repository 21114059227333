#root{
   width: 100vw;
   height: 100vh;
   overflow: hidden;
}

.grid-container-login {
   display: grid;
   height: 100%;
   grid-template-columns: auto;
   grid-template-rows: 25% 50px 350px auto;
   background-size: cover;
   background-position: center;
   background-image: url(../../logos/LoginBackground.jpg);  
}

.grid-container-login-with-alert {
   display: grid;
   height: calc(100vh - 48px);
   grid-template-columns: auto;
   grid-template-rows: 25% 50px 350px auto;
   background-size: cover;
   background-position: center;
   background-image: url(../../logos/LoginBackground.jpg);  
}

.grid-item-content-login {
   place-self: top;
}

.blurred-boxLogin {
   position: relative;
   text-align: -webkit-center;
   width: 400px;
   height: 375px;
   transform: translate(-50%, -50%);
   top: 50%;
   left: 50%;
   border-radius: 10px;
   overflow: hidden;
   grid-column: 1;
   grid-row: 3;
}

.blurred-boxLogin:after {
   content: "";
   width: 600px;
   height: 375px;
   position: absolute;
   left: 0px;
   right: 0;
   top: 0px;
   bottom: 0;
   box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.15);
   filter: blur(50px);
   backdrop-filter: blur(5px);
}

.login {
   position: relative;
   margin-top: 15px;
   z-index: 1;
   color: white !important;
   padding: 3%;
}

.login > * {
   width: 300px;
   margin-bottom: 15px;
}

.login h1 {
   color: #fff;
   text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   letter-spacing: 1px;
   text-align: center;
}

#forgotpassLink {
   cursor: pointer;
}

.inputTextbox {
  width: 100%;
  height: 40px !important;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #ffffff !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}

#loginButton {
   width: 40%;
   margin-top: 1.5rem;
}

#forgotpassLink {
   margin-top: 1rem;
}
