#root {
   width: 100vw;
   height: 100vh;
}

.icon-bristol-docusign {
   font-size: 2em !important;
   display: inline-block;
   color: #21376b;
   margin-right: "1rem";
}

.icon-containerport-docusign {
   font-size: 2em !important;
   display: inline-block;
   color: #002D72;
   margin-right: "1rem";
}

.icon-middlebay-docusign {
   font-size: 2em !important;
   display: inline-block;
   color: #8F9764;
   margin-right: "1rem";
}

.icon-failed-docusign {
   font-size: 2em !important;
   display: inline-block;
   color: #f44336;
   margin-right: "1rem";
}

.grid-item-border-bristol-docusign {
   background-color: #21376b;
   z-index: 100;
   height: 100vh
}

.grid-item-border-containerport-docusign {
   background-color: #002D72;
   z-index: 100;
   height: 100vh
}

.grid-item-border-middlebay-docusign {
   background-color: #8F9764;
   z-index: 100;
   height: 100vh
}

.grid-item-icon {
   place-self: center;
   align-content: center;
   align-items: center;
   min-height: max-content;
   min-width: max-content;
}

.grid-item-title-docusign {
   color: gray;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
   font-weight: 400;
   font-size: x-large;
   place-self: center;
   align-content: center;
   min-height: 5em;
}

.grid-item-message-docusign {
    text-align-last: center;
    font-family: Arial, Helvetica;
    font-weight: 400!important;
    color: grey;
    letter-spacing: 0.02em;
    font-size: large!important;
    text-align: center;
}

.grid-item-spacer-docusign {
   padding: 20px;
   font-size: 30px;
   text-align: center;
}