
 .TransferList-cards{
  height: 100%; 
  width: 100%; 
  min-height: 300px;  
  overflow: auto;
 }

.TransferList-card-header1{
  background-color: #010440;
  color: white;
}
