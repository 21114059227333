.checkvoid-wrapper{
   position:fixed;
   height:110vh;
   width:110vw;
 }
 
 .checkvoid-wrapper-fullscreen{
   height: 200px;
   width: 200px;
   position:absolute;
   top:40%;
   left:45%;
   transform:translateX(-50%) translateY(-50%) scale(1.5);
   border-radius: 100%;
   overflow:hidden;
 }

 .checkvoid-wrapper-fullscreen:after{
   font-size:18px;
   position:absolute;
   bottom:0px;
   text-align:center;
   width:100%;
   padding-top:10px;
   padding-bottom:20px;
 }

 .checkvoid{
   height:110px;
   width:150px;
   position:absolute;  
   bottom:40px;
   left: calc(50% + 10px);
   transform: translateX(-50%);
 }
 
 .checkvoid > .checkvoid-container {
   height:55px;
   border: 1px solid black;
   width:130px;
   position:absolute;
   top:10px;
   background-color:white; 
 }

 .checkvoid > .line1 {
   margin-top: 10px;
   margin-left: 90px;
   border-top: 2px solid grey;
   width:25px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .checkvoid > .line1-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 5px;
  margin-left: 90px;
  width:25px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .checkvoid > .line2 {
   margin-top: 20px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:85px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .checkvoid > .line2-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 15px;
  margin-left: 5px;
  width:85px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .checkvoid > .line3 {
   margin-top: 20px;
   margin-left: 95px;
   border-top: 2px solid grey;
   width:30px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .checkvoid > .line3-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 15px;
  margin-left: 95px;
  width:30px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .checkvoid > .line4 {
   margin-top: 30px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:120px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .checkvoid > .line4-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 25px;
  margin-left: 5px;
  width:120px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .checkvoid > .line5 {
   margin-top: 44px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:55px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .checkvoid > .line5-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 39px;
  margin-left: 5px;
  width:55px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .checkvoid > .line6 {
   margin-top: 44px;
   margin-left: 65px;
   border-top: 2px solid grey;
   width:60px;
   position:absolute;
   top:14px;
   background-color:white; 
 }
 
 .checkvoid > .line6-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 39px;
  margin-left: 65px;
  width:60px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

.void {
  padding: 5px;
  border: 1px solid red;
  position:absolute;
  top: 19px;
  left: 40px;
  background-color:transparent; 
  transform: rotate(-20deg);
  color: red;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  font-size: 18px;
  font-family: 'Courier New', monospace;
}