.AdoptSign-title{
  background-color: #002D72;
  color: white;
}

.AdoptSign-title-middleBay{
  background-color: #8f9879;
  color: white;
}

.AdoptSign-title-bristol{
  background-color: #1E376B;
  color: white;
}

.AdoptSign-card-header{
  background-color: #010440;
  color: white;
}

.AdoptSign-card-header-middleBay{
  background-color: #606651;
  color: white;
}


.AdoptSign-card-header-bristol{
  background-color: #010440;
  color: white;
}