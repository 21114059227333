.App{
  margin: 0;
  padding: 0;
 }

 .App-body{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-image:url(../src/logos/LoginBackground.jpg);
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica;
  letter-spacing: 0.02em;
   font-weight: 400;
  -webkit-font-smoothing: antialiased; 
 }
 
 .App-blurred-box{
   position: relative;
   width: 300px;
   height: 350px;
   transform: translate(-50%, -50%);
   top:50%;
   left:50%;
   background: inherit;
   border-radius: 10px;
   overflow: hidden;
 }
 
 .App-blurred-box:after{
  content: '';
  width: 500px;
  height: 300px;
  background: inherit; 
  position: absolute;
  left: -25px;
  right: 0;
  top: -20px;  
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
  backdrop-filter: blur(50px);
 }
 
 .App-login{
   position: relative;
   margin-top: 30px;
   text-align: center;
   z-index: 1;
   color: white;
 }
 .App-login > *{
   display: inline-block;
   width: 200px;
   margin-bottom: 15px;
 }
 
 .App-login h1 { color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.3); letter-spacing:1px; text-align:center; }

 .App-input.MuiInputBase-input.MuiInput-input{
  width: 100%;
  margin-bottom: 10px;
  background: rgba(0,0,0,0.3);
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2);
  -webkit-transition: box-shadow .5s ease;
  -moz-transition: box-shadow .5s ease;
  -o-transition: box-shadow .5s ease;
  -ms-transition: box-shadow .5s ease;
  transition: box-shadow .5s ease;
 }
 .App-input.MuiInputBase-input.MuiInput-input:focus{
     box-shadow: inset 0 -5px 45px rgba(100,100,100,0.4), 0 1px 1px rgba(255,255,255,0.2);
  }

.App-MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl::before{
    border-bottom: none!important;
    transition: none!important;
  }
  .App-MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl::after{
    border-bottom: none!important;
    transition: none!important;
  } 

  .App-MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary{
    width: 50%;
  }
 