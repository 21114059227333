#root{
   width: 100vw;
   height: 100vh;
   overflow: hidden;
}

.grid-container-registration {
   display: grid;
   height: 100%;
   grid-template-columns: auto;
   grid-template-rows: 25% 50px 350px auto;
   background-size: cover;
   background-position: center;
   background-image: url(../../logos/LoginBackground.jpg);  
}

.grid-container-registration-with-alert {
   display: grid;
   height: calc(100vh - 48px);
   grid-template-columns: auto;
   grid-template-rows: 25% 50px 350px auto;
   background-size: cover;
   background-position: center;
   background-image: url(../../logos/LoginBackground.jpg);  
}

.grid-item-content-registration {
   place-self: top;
}

.blurred-box-registration {
   position: relative;
   text-align: -webkit-center;
   width: 450px;
   height: 600px;
   transform: translate(-50%, -50%);
   top: 50%;
   left: 50%;
   border-radius: 10px;
   overflow: hidden;
   grid-column: 1;
   grid-row: 3;
}

.blurred-box-registration:after {
   content: "";
   width: 450px;
   height: 600px;
   position: absolute;
   left: 0px;
   right: 0;
   top: 0px;
   bottom: 0;
   box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.15);
   filter: blur(50px);
   backdrop-filter: blur(5px);
}

.register {
   position: relative;
   margin-top: 15px;
   z-index: 1;
   color: white !important;
   padding: 3%;
}

.register > * {
   width: 450px;
   margin-bottom: 15px;
}

.register h1 {
   color: #fff;
   text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   letter-spacing: 1px;
   text-align: center;
}

.inputTextbox-registration {
   width: 95%;
   height: 40px !important;
   margin-bottom: 10px;
   background: rgba(0, 0, 0, 0.5);
   outline: none;
   padding: 10px;
   font-size: 13px;
   color: #ffffff !important;
   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
   border: 1px solid rgba(0, 0, 0, 0.3);
   border-radius: 4px;
   box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
   -webkit-transition: box-shadow 0.5s ease;
   -moz-transition: box-shadow 0.5s ease;
   -o-transition: box-shadow 0.5s ease;
   -ms-transition: box-shadow 0.5s ease;
   transition: box-shadow 0.5s ease;
}

#registerButton {
   width: 40%;
   margin-top: 1.5rem;
}