.activationDetails {
   margin: 0;
   padding: 0;
}

.activationDetailsRoot {
   padding-top: 100px;
   height: calc(100vh);
   overflow: hidden;
   background-color: #ffffff;
}

.activationDetailsHeader {
   height: 80px;
   width: 100vw;
   z-index: 2;
   background-color: #ffffff;
   color: #002d72;
   position: sticky;
   overflow: hidden;
   top: 0;
}

.activationDetailsBody {
   position: relative;
   width: 100%;
   height: calc(100vh - 465px);
   background: inherit;
   z-index: 0;
   background-color: #ffffff;
   overflow-y: scroll;
   -ms-overflow-style: none;
   scrollbar-width: none;
   &::-webkit-scrollbar {
      display: none;
   }
   @media screen and (max-width: 1350px) {
      height: calc(100vh - 500px);
   }
   @media screen and (max-width: 1000px) {
      height: calc(100vh - 550px);
   }
}
