#root{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
 }
 
 .divbody-passwordReset{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-image:url(../../logos/LoginBackground.jpg);
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica;
  letter-spacing: 0.02em;
   font-weight: 400;
  -webkit-font-smoothing: antialiased; 
 }

 .divbody-passwordReset-with-alert{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-image:url(../../logos/LoginBackground.jpg);
  width: 100%;
  height: calc(100vh - 48px);
  font-family: Arial, Helvetica;
  letter-spacing: 0.02em;
   font-weight: 400;
  -webkit-font-smoothing: antialiased; 
 }

.grid-container-passwordReset {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 25% 50px 350px auto;
}

.grid-item-content-passwordReset {
  place-self: top;
}

 .blurred-passwordReset{
   position: relative;
   text-align: -webkit-center;
   width: 500px;
   height: 350px;
   transform: translate(-50%, -50%);
   top:50%;
   left:50%;
   border-radius: 10px;
   overflow: hidden;
   grid-column: 1;
   grid-row: 3;
 }
 
 .blurred-passwordReset:after{
  content: '';
  width: 500px;
  height: 350px; 
  position: absolute;
  left: 0px;
  right: 0;
  top: 0px;  
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.15);
  filter: blur(50px) ;
  backdrop-filter: blur(5px);
 }
 
 .passwordReset{
   position: relative;
   margin-top: 30px;
   text-align: -webkit-center;
   z-index: 1;
   color: white;
   display: table-caption;
   padding: 3%;
 }
 .passwordReset > *{
   width: 300px;
   margin-bottom: 15px;
 }
 
.passwordReset h1 { 
  color: #fff; 
  text-shadow: 0 0 10px rgba(0,0,0,0.3); 
  letter-spacing:1px; 
  text-align:center; 
}

.inputTextbox-passwordReset {
  width: 100%;
  height: 40px !important;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: #ffffff !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}
