.filters_menu {
   > button {
      padding: 6px 12px 6px 16px;
      border-radius: 100px;
      border: 1px solid #010440;
   }
}

.menu_header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 16px;
}

.menu_filter_option {
   border-bottom: 1px solid #bbbdc0;
   margin: 16px 0;
   width: 100%;
}

.date_pickers_container {
   display: flex;
   align-items: center;
   margin: 16px 0;
}

.menu_footer {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: 16px;
   padding-top: 26px;

   > button {
      padding: 7px 16px;
      border-radius: 100px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
   }
}
