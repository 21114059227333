.CompanyDetails{
   margin: 0;
   padding: 0;
  }
 
  .CompanyDetails-root{
   margin-top: 78px;
   height: calc(100vh - 78px);
   overflow: auto;
   background-color: #F2F2F2;
  }
 
  .CompanyDetails-header{
   border-bottom: 1px solid #2F3136;
   height: 60px;
   width: 100vw;
   z-index: 2;
   background-color: #F2F2F2;
   color: #778BA3;
   position: sticky;
   top: 0;
  }
 
  .CompanyDetails-body{
    position: relative;
    width: 100%;
    height: calc(100vh - 138px);
    background: inherit;
    overflow: auto;
    z-index: 0;
    background-color: #FAFBFC;
  }
  
  .CompanyDetails-layout{
   position: relative;
   text-align: center;
   z-index: 1;
   color: white;
 }
 
 .CompanyDetails-layout-drawer-open{
   position: relative;
   text-align: center;
   z-index: 1;
   color: white;
 }
 
 .CompanyDetails-card-header1{
   background-color: #002D72;
   color: white;
 }
 
 .CompanyDetails-card-header1{
  background-color: #002D72;
  color: white;
}

 .CompanyDetails-Accordion{
    width: inherit;
 }
 
 div.MuiAccordionDetails-root{
    display: block;
 }