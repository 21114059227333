.loader-wrapper{
   position:fixed;
   z-index:9999;
   height:110vh;
   width:110vw;
   background-color:rgba(240,240,240,0.5);
 }
 
 .check-wrapper-fullscreen{
   height: 200px;
   width: 200px;
   border: 2px solid #010440;
   position:absolute;
   top:45%;
   left:45%;
   transform:translateX(-50%) translateY(-50%) scale(1.5);
   background:#ffffff;
   border-radius: 100%;
   overflow:hidden;
   box-shadow:inset 0px 0px 5px 2px rgba(0,0,0,0.3),inset 0px 0px 3px 0px #010440;
 }

 
 .check-wrapper-fullscreen:after{
   content:'Loading';
   font-size:18px;
   position:absolute;
   bottom:0px;
   text-align:center;
   width:100%;
   border-top:3px solid #010440;
   background: rgb(1,4,64,1);
   background: -moz-linear-gradient(left, #010440 100%, #010440 50%);
   background: -webkit-linear-gradient(left, #002D72 0%,#002D72 100%);
   background: linear-gradient(to right, #002D72 10%,#010440 100%);
   color:white;
   padding-top:10px;
   padding-bottom:20px;
 }

 .check{
   height:110px;
   width:150px;
   position:absolute;  
   bottom:40px;
   left: calc(50% + 10px);
   transform: translateX(-50%);
 }
 
 .check > .check-container {
   height:55px;
   border: 1px solid black;
   width:130px;
   position:absolute;
   top:10px;
   background-color:white; 
 }

 .check > .line1 {
   margin-top: 10px;
   margin-left: 90px;
   border-top: 2px solid grey;
   width:25px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .check > .line1-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 5px;
  margin-left: 90px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  animation: write-1 5s linear infinite;
  white-space: nowrap;
  opacity: 0;
}

 .check > .line2 {
   margin-top: 20px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:85px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .check > .line2-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 15px;
  margin-left: 5px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  animation: write-2 5s linear infinite;
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .check > .line3 {
   margin-top: 20px;
   margin-left: 95px;
   border-top: 2px solid grey;
   width:30px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .check > .line3-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 15px;
  margin-left: 95px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  animation: write-3 5s linear infinite;
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .check > .line4 {
   margin-top: 30px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:120px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .check > .line4-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 25px;
  margin-left: 5px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  animation: write-4 5s linear infinite;
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .check > .line5 {
   margin-top: 44px;
   margin-left: 5px;
   border-top: 2px solid grey;
   width:55px;
   position:absolute;
   top:14px;
   background-color:white; 
 }

 .check > .line5-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 39px;
  margin-left: 5px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  animation: write-5 5s linear infinite;
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

 .check > .line6 {
   margin-top: 44px;
   margin-left: 65px;
   border-top: 2px solid grey;
   width:60px;
   position:absolute;
   top:14px;
   background-color:white; 
 }
 
 .check > .line6-write {
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 6px;
  margin-top: 39px;
  margin-left: 65px;
  width:0px;
  position:absolute;
  top:14px;
  background-color:white; 
  animation: write-6 5s linear infinite;
  letter-spacing: -1px;
  overflow: hidden;
  color: #002D72;
  white-space: nowrap;
}

  @keyframes write-1{
  5%{
    width: 5px;
    opacity: 1.0;
  }

  10%{
    width: 15px;
    opacity: 1.0;
  }

  15%{
    width: 25px;
    opacity: 1.0;
  }

  20%{
    width: 25px;
    opacity: 1.0;
  }

  25%{
    width: 25px;
    opacity: 1.0;
  }

  30%{
    width: 25px;
    opacity: 1.0;
  }

  35%{
    width: 25px;
    opacity: 1.0;
  }

  40%{
    width: 25px;
    opacity: 1.0;
  }

  45%{
    width: 25px;
    opacity: 1.0;
  }

  50%{
    width: 25px;
    opacity: 1.0;
  }

  55%{
    width: 25px;
    opacity: 1.0;
  }

  60%{
    width: 25px;
    opacity: 1.0;
  }

  65%{
    width: 25px;
    opacity: 1.0;
  }

  70%{
    width: 25px;
    opacity: 1.0;
  }

  75%{
    width: 25px;
    opacity: .9;
  }

  80%{
    width: 25px;
    opacity: .7;
  }

  85%{
    width: 25px;
    opacity: .5;
  }

  90%{
    width: 25px;
    opacity: .3;
  }

  95%{
    width: 25px;
    opacity: .1;
  }

  100%{
    width: 25px;
    opacity: 0;
  }
}

@keyframes write-2{
  5%{
    width: 0px;
    opacity: 0;
  }

  10%{
    width: 0px;
    opacity: 0;
  }

  17%{
    width: 28px;
    opacity: 1.0;
  }

  20%{
    width: 56px;
    opacity: 1.0;
  }

  25%{
    width: 85px;
    opacity: 1.0;
  }

  30%{
    width: 85px;
    opacity: 1.0;
  }

  35%{
    width: 85px;
    opacity: 1.0;
  }

  40%{
    width: 85px;
    opacity: 1.0;
  }

  45%{
    width: 85px;
    opacity: 1.0;
  }

  50%{
    width: 85px;
    opacity: 1.0;
  }

  55%{
    width: 85px;
    opacity: 1.0;
  }

  60%{
    width: 85px;
    opacity: 1.0;
  }

  65%{
    width: 85px;
    opacity: 1.0;
  }

  70%{
    width: 85px;
    opacity: 1.0;
  }

  75%{
    width: 85px;
    opacity: .9;
  }

  80%{
    width: 85px;
    opacity: .7;
  }

  85%{
    width: 85px;
    opacity: .5;
  }

  90%{
    width: 85px;
    opacity: .3;
  }

  95%{
    width: 85px;
    opacity: .1;
  }

  100%{
    width: 85px;
    opacity: 0;
  }
}

@keyframes write-3{
  5%{
    width: 0px;
    opacity: 0;
  }

  10%{
    width: 0px;
    opacity: 0;
  }

  15%{
    width: 0px;
    opacity: 0;
  }

  20%{
    width: 0px;
    opacity: 0;
  }

  27%{
    width: 10px;
    opacity: 1.0;
  }

  30%{
    width: 20px;
    opacity: 1.0;
  }

  35%{
    width: 30px;
    opacity: 1.0;
  }

  40%{
    width: 30px;
    opacity: 1.0;
  }

  45%{
    width: 30px;
    opacity: 1.0;
  }

  50%{
    width: 30px;
    opacity: 1.0;
  }

  55%{
    width: 30px;
    opacity: 1.0;
  }

  60%{
    width: 30px;
    opacity: 1.0;
  }

  65%{
    width: 30px;
    opacity: 1.0;
  }

  70%{
    width: 30px;
    opacity: 1.0;
  }

  75%{
    width: 30px;
    opacity: .9;
  }

  80%{
    width: 30px;
    opacity: .7;
  }

  85%{
    width: 30px;
    opacity: .5;
  }

  90%{
    width: 30px;
    opacity: .3;
  }

  95%{
    width: 30px;
    opacity: .1;
  }

  100%{
    width: 30px;
    opacity: 0;
  }
}

@keyframes write-4{
  5%{
    width: 0px;
    opacity: 0;
  }

  10%{
    width: 0px;
    opacity: 0;
  }

  15%{
    width: 0px;
    opacity: 0;
  }

  20%{
    width: 0px;
    opacity: 0;
  }

  25%{
    width: 0px;
    opacity: 0;
  }

  30%{
    width: 0px;
    opacity: 0;
  }

  37%{
    width: 30px;
    opacity: 1.0;
  }

  40%{
    width: 60px;
    opacity: 1.0;
  }

  45%{
    width: 90px;
    opacity: 1.0;
  }

  50%{
    width: 120px;
    opacity: 1.0;
  }

  55%{
    width: 120px;
    opacity: 1.0;
  }

  60%{
    width: 120px;
    opacity: 1.0;
  }

  65%{
    width: 120px;
    opacity: 1.0;
  }

  70%{
    width: 120px;
    opacity: 1.0;
  }

  75%{
    width: 120px;
    opacity: .9;
  }

  80%{
    width: 120px;
    opacity: .7;
  }

  85%{
    width: 120px;
    opacity: .5;
  }

  90%{
    width: 120px;
    opacity: .3;
  }

  95%{
    width: 120px;
    opacity: .1;
  }

  100%{
    width: 120px;
    opacity: 0;
  }
}

@keyframes write-5{
  5%{
    width: 0px;
    opacity: 0;
  }

  10%{
    width: 0px;
    opacity: 0;
  }

  15%{
    width: 0px;
    opacity: 0;
  }

  20%{
    width: 0px;
    opacity: 0;
  }

  25%{
    width: 0px;
    opacity: 0;
  }

  30%{
    width: 0px;
    opacity: 0;
  }

  35%{
    width: 0px;
    opacity: 0;
  }

  40%{
    width: 0px;
    opacity: 0;
  }

  45%{
    width: 0px;
    opacity: 0;
  }

  52%{
    width: 18px;
    opacity: 1.0;
  }

  55%{
    width: 36px;
    opacity: 1.0;
  }

  60%{
    width: 55px;
    opacity: 1.0;
  }

  65%{
    width: 55px;
    opacity: 1.0;
  }

  70%{
    width: 55px;
    opacity: 1.0;
  }

  75%{
    width: 55px;
    opacity: .9;
  }

  80%{
    width: 55px;
    opacity: .7;
  }

  85%{
    width: 55px;
    opacity: .5;
  }

  90%{
    width: 55px;
    opacity: .3;
  }

  95%{
    width: 55px;
    opacity: .1;
  }

  100%{
    width: 55px;
    opacity: 0;
  }
}

@keyframes write-6{
  5%{
    width: 0px;
    opacity: 0;
  }

  10%{
    width: 0px;
    opacity: 0;
  }

  15%{
    width: 0px;
    opacity: 0;
  }

  20%{
    width: 0px;
    opacity: 0;
  }

  25%{
    width: 0px;
    opacity: 0;
  }

  30%{
    width: 0px;
    opacity: 0;
  }

  35%{
    width: 0px;
    opacity: 0;
  }

  40%{
    width: 0px;
    opacity: 0;
  }

  45%{
    width: 0px;
    opacity: 0;
  }

  50%{
    width: 0px;
    opacity: 0;
  }

  55%{
    width: 0px;
    opacity: 0;
  }

  62%{
    width: 20px;
    opacity: 1.0;
  }

  65%{
    width: 40px;
    opacity: 1.0;
  }

  70%{
    width: 60px;
    opacity: 1.0;
  }

  75%{
    width: 60px;
    opacity: .9;
  }

  80%{
    width: 60px;
    opacity: .7;
  }

  85%{
    width: 60px;
    opacity: .5;
  }

  90%{
    width: 60px;
    opacity: .3;
  }

  95%{
    width: 60px;
    opacity: .1;
  }

  100%{
    width: 60px;
    opacity: 0;
  }
}