.ApplicationNew-title{
  background-color: #002D72; 
  color: white; 
  text-align: center
}

 .ApplicationNew-cards{
  height: 100%; 
  width: 100%; 
  max-height: 400px; 
  overflow: auto;
 }

.ApplicationNew-card-header1{
  background-color: #002D72;
  color: white;
}

.ApplicationNew-card-header2{
  background-color: #010440;
  color: white;
}
