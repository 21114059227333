.main_container {
   width: 100%;
   height: calc(100vh - 78px);
   margin-top: 78px;
   background-color: #ffffff;
   padding-top: 42px;
   padding-bottom: 42px;
   padding-right: 70px;
   overflow: scroll;
}

.loader_container {
   width: 100%;
   height: 500px;
}

.sidebar_open {
   padding-left: 310px;
   transition: padding-left 0.3s;
}

.sidebar_closed {
   padding-left: 125px;
   transition: padding-left 0.3s;
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   border-bottom: 1px solid #bbbdc0;

   > h1 {
      color: #010440;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.15px;
   }

   > button {
      border-radius: 100px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      background-color: #5357a4;
   }
}
