#root {
   width: 100vw;
   height: 100vh;
}

.grid-container {
   display: grid;
   grid-template-columns: auto;
   grid-template-rows: 5vh 5vh 25vh 20vh 20vh 20vh 5vh !important;
   background-color: white;
   min-width: max-content;
}

.textContainer {
   width: 60%;
   transform: translate(40%, 0%);
}

.key-icon-bristol {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #21376b;
}

.key-icon-containerport {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #002D72;
}

.key-icon-middlebay {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #8F9764;
}

.grid-item-border-bristol {
   background-color: #21376b;
   z-index: 100;
}

.grid-item-border-containerport {
   background-color: #002D72;
   z-index: 100;
}

.grid-item-border-middlebay {
   background-color: #8F9764;
   z-index: 100;
}

.grid-item-icon {
   place-self: center;
   align-content: center;
   align-items: center;
   min-height: max-content;
   min-width: max-content;
}

.grid-item-title {
   color: gray;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
   font-weight: 400;
   font-size: xx-large;
   place-self: center;
   align-content: center;
   min-height: 5em;
}

.grid-item-message {
   place-self: center;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
   background-color: white;
   color: gray;
   font-size: x-large;
   min-height: max-content;
   min-width: max-content;
   z-index: 50;
}

.grid-item-spacer {
   padding: 20px;
   font-size: 30px;
   text-align: center;
}

