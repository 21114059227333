.container {
   margin: 30px 0px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.status_filter {
   display: flex;
   justify-content: space-between;

   > p {
      color: #010440;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
   }
}

.search_filter {
   width: 100%;
   display: grid;
   grid-template-columns: 255px auto;
   align-items: center;
   gap: 25px;
}

.filter_chip {
   border: 1px solid #5357a4 !important;
   > span {
      font-size: 12px;
      font-weight: 600;
      color: #5357a4;
   }
}
