.DivisionView{
   margin: 0;
   padding: 0;
  }
 
  .DivisionView-root{
   margin-top: 78px;
   height: calc(100vh - 78px);
   overflow: auto;
   background-color: #F2F2F2;
  }
 
  .DivisionView-header{
   border-bottom: 1px solid #2F3136;
   height: 60px;
   width: 100vw;
   z-index: 2;
   background-color: #F2F2F2;
   color: #778BA3;
   position: sticky;
   top: 0;
  }
 
  .DivisionView-body{
    position: relative;
    width: 100%;
    height: calc(100vh - 138px);
    background: inherit;
    overflow: auto;
    z-index: 0;
    background-color: #FAFBFC;
  }
  
  .DivisionView-layout{
   margin-top: 1rem;
   margin-left: 1rem;
   margin-right: 1rem;
   margin-bottom: 1rem;
   position: relative;
   text-align: center;
   z-index: 1;
   color: white;
 }
 
 .DivisionView-layout-drawer-open{
   margin-top: 1rem;
   margin-right: 1rem;
   margin-bottom: 1rem;
   position: relative;
   text-align: center;
   z-index: 1;
   color: white;
 }
 
 .DivisionView-invite {
   float: right;
   margin-right: 1rem;
   background-color: #e6e3e3;
   color: #778BA3;
   border-radius: 4px;
   min-width: 100px;
   text-align: center;
 }
 
 .DivisionView-invite:hover {
   background-color: #dddada;
 }
 
 .DivisionView-invite-label {
   flex-direction: column;
 }
 
 .DivisionView-card-active{
   margin-left: 2.5rem;
   margin-top: 1.5rem;
   height: 300px;
   width: 300px;
 }
 
 .DivisionView-card-inactive{
   margin-left: 2.5rem;
   margin-top: 1.5rem;
   height: 300px;
   width: 300px;
   opacity: 0.6;
 }
 
 .DivisionView-card-header1{
   background-color: #212121;
   color: white;
 }
 
 .DivisionView-card-header2{
   background-color: #212121;
   color: white;
 }
 
 .DivisionView-card-header3{
   background-color: #212121;
   color: white;
 }
 