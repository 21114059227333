.container {
   background-color: #f1f1f1;
   width: 100%;
   height: 100%;
   overflow-y: auto;
   padding: 60px 90px;
   @media (max-width: 600px) {
      padding: 20px 30px;
   }
   @media ((min-width: 601px) and (max-width: 1000px)) {
      padding: 40px 60px;
   }
}

.section {
   margin-bottom: 24px;
   background-color: #fff;
   border-radius: 8px;
}

.browserText {
   text-align: right;
   font-weight: 600;
   padding-bottom: 10px;
}
