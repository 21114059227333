.UserDetails{
  margin: 0;
  padding: 0;
 }

 .UserDetails-root{
  margin-top: 78px;
  height: calc(100vh - 78px);
  overflow: auto;
  background-color: #F2F2F2;
 }

 .UserDetails-header{
  border-bottom: 1px solid #2F3136;
  height: 60px;
  width: 100vw;
  z-index: 2;
  background-color: #F2F2F2;
  color: #778BA3;
  position: sticky;
  top: 0;
 }

 .UserDetails-body{
   position: relative;
   width: 100%;
   height: calc(100vh - 138px);
   background: inherit;
   overflow: auto;
   z-index: 0;
   background-color: #FAFBFC;
 }
 
 .UserDetails-layout{
  position: relative;
  text-align: center;
  z-index: 1;
  color: white;
}

.UserDetails-layout-drawer-open{
  position: relative;
  text-align: center;
  z-index: 1;
  color: white;
}

.UserDetails-card-header1{
  background-color: #002D72;
  color: white;
}
