#root {
   width: 100vw;
   height: 100vh;
}

.key-icon-bristol-expired {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #21376b;
}

.key-icon-UWL-expired {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #006241;
}

.key-icon-WDS-expired {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #006241;
}

.key-icon-containerport-expired {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #002D72;
}

.key-icon-middlebay-expired {
   font-size: 2em !important;
   display: inline-block;
   transform: rotateY(180deg) rotate(45deg);
   color: #8F9764;
}

.grid-item-border-bristol-expired {
   background-color: #21376b;
   z-index: 100;
}

.grid-item-border-containerport-expired {
   background-color: #002D72;
   z-index: 100;
   padding: 20px;
   font-size: 30px;
   text-align: center;
}

.grid-item-border-UWL-expired {
   background-color: #006241;
   z-index: 100;
   padding: 20px;
   font-size: 30px;
   text-align: center;
}

.grid-item-border-WDS-expired {
   background-color: #006241;
   z-index: 100;
   padding: 20px;
   font-size: 30px;
   text-align: center;
}

.grid-item-border-middlebay-expired {
   background-color: #8F9764;
   z-index: 100;
}

.grid-item-icon {
   place-self: center;
   align-content: center;
   align-items: center;
   min-height: max-content;
   min-width: max-content;
}

.grid-item-title-expired {
   color: gray;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
   font-weight: 400;
   font-size: x-large;
   place-self: center;
   align-content: center;
   min-height: 5em;
}

.grid-item-message-Expired {
    text-align-last: center;
    font-family: Arial, Helvetica;
    font-weight: 400!important;
    color: grey;
    letter-spacing: 0.02em;
    font-size: large!important;
    text-align: center;
}

.grid-item-spacer-expired {
   padding: 20px;
   font-size: 30px;
   text-align: center;
}

